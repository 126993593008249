<script>
import { RiderUserConfig } from '@/config/RiderUserConfig'
import { VoucherConfig } from '@/config/VoucherConfig'

import { EdgeStack } from '@/components/modals'
import VoucherBlock from './blocks/VoucherBlock.vue'
import InputLabel from '@/components/form/InputLabel'
import { useEndpoints } from '@/composables'
// import RiderDropdown from './RiderDropdown.vue'
import { httpClient } from '@/services'
export default {
  name: 'notifyRider',
  components: {
    EdgeStack,
    VoucherBlock,
    InputLabel,
    // RiderDropdown,
    AnchorButton: () => import('@/components/form/AnchorButton.vue'),
  },

  props: {
    esId: {
      type: String,
      default: 'notifyRider',
    },
    primaryKey: {
      required: false,
    },
    riderData: {
      type: Object,
      required: false,
    },
    rawData: {
      type: Object,
      required: false,
    },
    fleets: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    form: {},
    busy: false,
    isReq: false,
    loadReq: false,
    riderList: [],
    isSelected: false,
    totalFleetRiderCount: 0,
  }),

  computed: {},

  watch: {
    'riderData': {
      deep: true,
      immediate: true,
      handler(newData) {
        this.form = newData
      },
    },
    'primaryKey': {
      deep: false,
      immediate: false,
      handler(newKey) {
        this.primaryKey = newKey
      },
    },
    'form.assigned_fleets'(newValue) {
      if (newValue.length > 0) {
        this.isSelected = true
        this.setTotalRider()
      } else {
        this.isSelected = false
        this.form.selected_riders = []
        this.form.rider = ''
        this.form.invitation_type = false
        this.totalFleetRiderCount = 0
      }
    },
    'form.selected_riders'(newValue) {
      if (newValue.length > 0) {
        this.setTotalRider()
      } else {
        this.totalFleetRiderCount = 0
      }
    },
    'form.invitation_type'() {
      this.setTotalRider()
    },
  },
  methods: {
    async getRiderList(query) {
      let fleetQuery = ''

      if (this.form.assigned_fleets && this.form.assigned_fleets.length) {
        fleetQuery = `&${this.form.assigned_fleets
          .map((item) => `fleets=${item}`)
          .join('&')}`
      }

      const { data } = await this.$http.get(
        `${RiderUserConfig.api.index}?search=${query}${fleetQuery}`
      )
      let list = []
      if (data?.data.length > 0) {
        data.data.forEach(function(row) {
          list.push({
            name: row.full_name,
            text: `${row.full_name} (${row.phone_number})`,
            value: row.id,
            phone: row.phone_number,
            nearby_fleet: row?.nearby_fleet?.name,
          })
        })
      }
      this.riderList = list

      return { results: this.riderList }
    },
    getSelectedRider(id) {
      let rider = this.riderList.find((row) => row.value === id)
      if (rider) {
        this.form.selected_riders.unshift(rider)
      }
    },
    async setTotalRider() {
      if (this.form.invitation_type) {
        this.totalFleetRiderCount = this.form.selected_riders.length
      } else {
        if (this.form.assigned_fleets.length > 0) {
          this.totalFleetRiderCount = 0
          this.loadReq = true
          await this.totalFleetRider()
        }
      }
    },
    async totalFleetRider() {
      const fleetUrls = this.form.assigned_fleets.map((fleetId) =>
        useEndpoints.fleet.details(fleetId)
      )
      const fleetUrlsPromises = fleetUrls.map((url) => httpClient.get(url))
      await httpClient
        .all(fleetUrlsPromises)
        .then(
          await httpClient.spread((...responses) => {
            console.log('fleetUrls-response', responses)
            this.loadReq = false
            this.totalFleetRiderCount = responses.reduce(
              (sum, item) => (sum += item.data.summary.total_rider),
              0
            )

            console.log('fleetUrls-total', this.totalFleetRiderCount)
          })
        )
        .catch((err) => {
          console.log({ fetchNotesEr: err })
        })
      console.log('fleetUrls', fleetUrls)
    },
    async pushVoucher() {
      if (this.loadReq) return
      let rider_ids = []
      this.isReq = true
      const formData = new FormData()
      if (this.form.invitation_type) {
        rider_ids = this.form.selected_riders.map((row) => row.value)
        rider_ids.forEach((id, key) => {
          formData.append(`rider_ids[${key}]`, id)
        })
      }

      formData.set('invitation_type', this.form.invitation_type)

      this.$http
        .post(VoucherConfig.api.pushVoucher(this.primaryKey), formData)
        .then(({ data }) => {
          this.$emit('save', data)

          this.$edgeStack.close(this.esId)
          this.$edgeStack.emitter.on(
            this.$edgeStack.getEventName('closed', this.esId),
            () => {
              this.form.assigned_fleets = []
              this.form.invitation_type = false
              this.form.rider = ''
              this.form.selected_riders = []
            }
          )

          this.$notify(
            {
              group: 'bottomLeft',
              type: 'success',
              title: `Voucher pushed successfully`,
              text: `The voucher pushed successfully`,
            },
            5000
          )
        })
        .catch((err) => {
          console.log('onSaveErr', err, err.response)
          this.$notify(
            {
              group: 'bottomLeft',
              type: 'error',
              title: `Error occured [${err?.response?.status}]`,
              text:
                err.response?.data?.detail ??
                `Failed to push voucher, please try agian.`,
            },
            5000
          )
        })
        .finally(() => (this.isReq = false))
    },
    removeRider(key) {
      this.form?.selected_riders.splice(key, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.panel-title {
  font-size: 22px;
  font-weight: 500;
  color: #2e2e39;
}

.list-item {
  display: grid;
  align-items: start;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 10px;
  padding: 10px;
  font-size: 14px;

  & .title {
    grid-column: span 1 / span 1;
    font-weight: 700;
    color: #2e2e39;
  }

  & .value {
    grid-column: span 2 / span 2;
    color: #6d6d75;
  }
}
</style>

<template>
  <EdgeStack
    :id="esId"
    :busy="busy"
    :exitButton="{ text: 'Cancel', hidden: false }"
    class="relative"
    :size-class="`w-full md:w-1/2`"
  >
    <loading :active.sync="loadReq" :is-full-page="false" />
    <template #header>
      <div class="flex justify-between -mt-8">
        <div class="flex w-full items-start justify-between">
          <div class="panel-title font-bold">Notify Riders</div>
          <VoucherBlock
            :voucher_type="rawData.voucher_type"
            :reward_amount="rawData.reward_amount"
            :code="rawData.code"
          />
        </div>
      </div>
    </template>
    <div class="px-4">
      <div>
        <input-label text="Fleet" />
        <TRichSelect
          v-model="form.assigned_fleets"
          :options="fleets"
          :close-on-select="false"
          placeholder="Select"
          multiple
        />
      </div>
      <div class="mt-4" v-if="isSelected">
        <div class="flex justify-items-start items-center">
          <label class="font-bold mr-4">Invitation Type</label>
          <label class="cursor-pointer mr-4 flex items-center">
            <TRadioUnwrapped
              v-model="form.invitation_type"
              :value="false"
              :name="`invitation_type`"
            />

            <span class="ml-3 font-medium text-14px text-oDark"> All </span>
          </label>
          <label class="cursor-pointer flex items-center">
            <TRadioUnwrapped
              v-model="form.invitation_type"
              :value="true"
              :name="`invitation_type`"
            />

            <span class="ml-3 font-medium text-14px text-oDark"> Custom </span>
          </label>
        </div>
      </div>
      <div class="mt-4" v-if="form.invitation_type & isSelected">
        <label class="font-bold mr-4">Search rider or id</label>
        <!-- <RiderDropdown
          class="w-full"
          v-model="form.riders"
          :options="riderList"
        /> -->
        <AppInput
          v-model="form.rider"
          type="richselect"
          name="riders"
          rules="required"
          placeholder="Search rider or id"
          text-attribute="text"
          :delaySearchTime="1000"
          :fetch-options="getRiderList"
          @input="getSelectedRider"
        />
      </div>
      <div v-if="form.invitation_type & isSelected">
        <table :id="`riderTable`" class="fst">
          <!-- header -->
          <thead class="fst-head">
            <tr class="fst-head-row">
              <th class="fst-head-row-item">Rider Name</th>
              <th class="fst-head-row-item">Phone</th>
              <th class="fst-head-row-item">Fleet</th>
              <th class="w-15"></th>
            </tr>
          </thead>
          <tbody class="fst-body" v-if="form.selected_riders.length > 0">
            <tr
              class="fst-row"
              v-for="(item, key) in form.selected_riders"
              :key="key"
            >
              <td class="fst-row-item">{{ item.name }}</td>
              <td class="fst-row-item">{{ item.phone }}</td>
              <td class="fst-row-item">{{ item.nearby_fleet }}</td>
              <td>
                <span
                  class="
                    action
                    transition-all
                    ease-linear
                    delay-500
                    w-5
                    h-5
                    items-center
                    rounded-full
                    bg-gray-800
                    text-white
                    font-bold
                    justify-center
                    p-1
                    cursor-pointer
                  "
                  @click="removeRider(key)"
                >
                  <i class="fas fa-times"></i>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <template #footer>
      <anchor-button
        variant="primary"
        :class="`w-full ml-3`"
        :isLoading="isReq"
        @click="pushVoucher"
      >
        Notify {{ totalFleetRiderCount }}
        {{ totalFleetRiderCount > 1 ? `Riders` : `Rider` }}
      </anchor-button>
    </template>
  </EdgeStack>
</template>

<style lang="scss" scoped>
@import './$fs-table.scss';
</style>

<template>
  <content-section>
    <div class="flex flex-col md:items-center md:justify-between md:flex-row">
      <div class="flex flex-col space-y-1 lg:items-baseline lg:flex-row">
        <div
          v-if="hideBackButton === false"
          class="mr-2 back-button"
          @click="handleClickingBack()"
        >
          <i class="fas fa-arrow-left"></i>
        </div>
        <span class="text-xl font-semibold text-gray-600"> {{ title }} / </span>
        <span class="text-3xl font-bold text-gray-800 lg:ml-2">
          <slot name="details">
            Details (#<span class="uppercase">{{
              detailsId | onlyLastFive
            }}</span
            >)
          </slot>
        </span>
      </div>
      <slot></slot>
    </div>
  </content-section>
</template>

<script>
import ContentSection from '@/components/layout/ContentSection'

export default {
  name: 'DetailsPageTitle',
  props: {
    title: { reqired: false, type: String },
    detailsId: { reqired: false, type: String },
    hideBackButton: { requred: false, type: Boolean, default: true },
    useHistoryForBackButton: { required: false, type: Boolean, default: true },
  },
  components: {
    ContentSection,
  },
  methods: {
    handleClickingBack() {
      this.$emit('back')

      if (this.useHistoryForBackButton === true && window.history.length > 1) {
        this.$router.go(-1)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.back-button {
  width: 32px;
  height: 32px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e4e4e4;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(54, 64, 76, 0.17);
}
</style>
